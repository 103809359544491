[
  {
    "filter_id": 1773,
    "id": 586048746,
    "meta.biking": 401.45156548718984,
    "meta.snowshoe_trekking": 1353.9,
    "meta.walking": 902.6024967865324,
    "meta.winter_hiking": 1128.3,
    "modified_at": "2024-09-08T10:43:04Z",
    "name": "240906 Sursee Brugg",
    "original_id": null,
    "timetype": "no",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1774,
    "id": 1301705526,
    "meta.biking": 25.86366902247938,
    "meta.snowshoe_trekking": 189.4,
    "meta.walking": 126.27615155232039,
    "meta.winter_hiking": 157.8,
    "modified_at": "2024-08-28T16:38:51Z",
    "name": "240823 Engelberg Aaschlucht",
    "original_id": null,
    "timetype": "wander",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1774,
    "id": 740593614,
    "meta.biking": 0.0,
    "meta.snowshoe_trekking": 0.0,
    "meta.walking": 199.51876394879008,
    "meta.winter_hiking": 0.0,
    "modified_at": "2024-08-28T13:13:55Z",
    "name": "240828 Grimsel Sidelhorn",
    "original_id": null,
    "timetype": "wander",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1773,
    "id": 696798449,
    "meta.biking": 274.65230804784585,
    "meta.snowshoe_trekking": 1372.0,
    "meta.walking": 914.6830190273032,
    "meta.winter_hiking": 1143.4,
    "modified_at": "2024-08-23T17:09:04Z",
    "name": "240823 Turren SörenbergRothorn Wilerhorn",
    "original_id": null,
    "timetype": "velo",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1774,
    "id": 855664553,
    "meta.biking": 113.68611301550807,
    "meta.snowshoe_trekking": 380.3,
    "meta.walking": 253.5652838593496,
    "meta.winter_hiking": 317.0,
    "modified_at": "2024-07-19T15:36:08Z",
    "name": "240719 4 Seen Wanderung",
    "original_id": null,
    "timetype": "wander",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1774,
    "id": 818656831,
    "meta.biking": 91.92465520608557,
    "meta.snowshoe_trekking": 434.4,
    "meta.walking": 289.62758303368383,
    "meta.winter_hiking": 362.0,
    "modified_at": "2024-07-06T13:33:58Z",
    "name": "240705 Alp Tschingla",
    "original_id": null,
    "timetype": "wander",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1773,
    "id": 567666713,
    "meta.biking": 0.0,
    "meta.snowshoe_trekking": 0.0,
    "meta.walking": 594.6647146123539,
    "meta.winter_hiking": 0.0,
    "modified_at": "2024-05-12T14:46:01Z",
    "name": "240512 Leidenberg Santenberg Lätte",
    "original_id": null,
    "timetype": "wander",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1773,
    "id": 979433070,
    "meta.biking": 276.6972918954365,
    "meta.snowshoe_trekking": 918.2,
    "meta.walking": 612.1458118030363,
    "meta.winter_hiking": 765.2,
    "modified_at": "2024-06-07T16:57:14Z",
    "name": "240607 Yeti Testrunde",
    "original_id": null,
    "timetype": "wander",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1773,
    "id": 1392864726,
    "meta.biking": 308.984410097627,
    "meta.snowshoe_trekking": 1067.4,
    "meta.walking": 711.5723314431797,
    "meta.winter_hiking": 889.5,
    "modified_at": "2024-04-13T20:43:05Z",
    "name": "240413 Sempachersee",
    "original_id": null,
    "timetype": "velo",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1774,
    "id": 1433818927,
    "meta.biking": 140.56658139347977,
    "meta.snowshoe_trekking": 634.6,
    "meta.walking": 423.0950126918782,
    "meta.winter_hiking": 528.9,
    "modified_at": "2023-10-02T06:35:17Z",
    "name": "230928 Arnigrat",
    "original_id": null,
    "timetype": "wander",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1773,
    "id": 2081877597,
    "meta.biking": 383.0845887287184,
    "meta.snowshoe_trekking": 1208.4,
    "meta.walking": 805.6118064747874,
    "meta.winter_hiking": 1007.0,
    "modified_at": "2023-10-01T16:03:14Z",
    "name": "231001 Chäserstatt_Trophy",
    "original_id": null,
    "timetype": "no",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1773,
    "id": 447841249,
    "meta.biking": 312.0786975551898,
    "meta.snowshoe_trekking": 927.3,
    "meta.walking": 618.2021339892186,
    "meta.winter_hiking": 772.8,
    "modified_at": "2023-09-30T16:15:13Z",
    "name": "230930 Grimselpass Chaeserstatt",
    "original_id": null,
    "timetype": "no",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1774,
    "id": 121870963,
    "meta.biking": 59.68682747451224,
    "meta.snowshoe_trekking": 245.0,
    "meta.walking": 163.3198236002289,
    "meta.winter_hiking": 204.1,
    "modified_at": "2023-09-17T14:12:25Z",
    "name": "230917 Napf",
    "original_id": null,
    "timetype": "wander",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1773,
    "id": 514565581,
    "meta.biking": 220.41521450941963,
    "meta.snowshoe_trekking": 744.2,
    "meta.walking": 496.12326420364013,
    "meta.winter_hiking": 620.2,
    "modified_at": "2023-09-16T19:56:18Z",
    "name": "230916 Leidenberg Flüss",
    "original_id": null,
    "timetype": "wander",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1774,
    "id": 1720718430,
    "meta.biking": 245.57421747048338,
    "meta.snowshoe_trekking": 813.7,
    "meta.walking": 542.4559723814034,
    "meta.winter_hiking": 678.1,
    "modified_at": "2023-08-11T15:27:15Z",
    "name": "230811 Schwalmis Oberbauenstock",
    "original_id": null,
    "timetype": "wander",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1774,
    "id": 18367928,
    "meta.biking": 28.577196875274172,
    "meta.snowshoe_trekking": 112.6,
    "meta.walking": 75.0545932417336,
    "meta.winter_hiking": 93.8,
    "modified_at": "2023-07-25T15:17:23Z",
    "name": "230725 Frick Foxtrail",
    "original_id": null,
    "timetype": "wander",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 77273,
    "id": 561520864,
    "meta.biking": 99.8,
    "meta.snowshoe_trekking": 479.8,
    "meta.walking": 319.9,
    "meta.winter_hiking": 399.9,
    "modified_at": "2023-07-18T19:27:28Z",
    "name": "Schiberg",
    "original_id": null,
    "timetype": "wander",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1773,
    "id": 951762333,
    "meta.biking": 237.51729070818112,
    "meta.snowshoe_trekking": 829.1,
    "meta.walking": 552.7052783742093,
    "meta.winter_hiking": 690.9,
    "modified_at": "2023-07-06T20:28:17Z",
    "name": "230706 Dierikon Sursee",
    "original_id": null,
    "timetype": "wander",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1773,
    "id": 726221900,
    "meta.biking": 176.30142653212545,
    "meta.snowshoe_trekking": 706.6,
    "meta.walking": 471.03493194548207,
    "meta.winter_hiking": 588.8,
    "modified_at": "2023-07-06T08:58:08Z",
    "name": "230706 Dierikon",
    "original_id": null,
    "timetype": "velo",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1773,
    "id": 631781668,
    "meta.biking": 632.6923519353121,
    "meta.snowshoe_trekking": 2117.4,
    "meta.walking": 1411.6273025632258,
    "meta.winter_hiking": 1764.5,
    "modified_at": "2023-06-17T10:08:50Z",
    "name": "230610 Ahorn",
    "original_id": null,
    "timetype": "velo",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1774,
    "id": 1572533992,
    "meta.biking": 21.10997670848539,
    "meta.snowshoe_trekking": 89.3,
    "meta.walking": 59.52356071938722,
    "meta.winter_hiking": 74.4,
    "modified_at": "2023-06-06T18:50:29Z",
    "name": "230606 Trechter",
    "original_id": null,
    "timetype": "wander",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1774,
    "id": 747639111,
    "meta.biking": 48.8015087899733,
    "meta.snowshoe_trekking": 147.0,
    "meta.walking": 98.00044761289202,
    "meta.winter_hiking": 122.5,
    "modified_at": "2023-05-28T19:58:30Z",
    "name": "230528 Balsthal Holzweg",
    "original_id": null,
    "timetype": "wander",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1773,
    "id": 69446493,
    "meta.biking": 211.0879238318261,
    "meta.snowshoe_trekking": 745.5,
    "meta.walking": 496.9794208909834,
    "meta.winter_hiking": 621.2,
    "modified_at": "2023-05-28T19:51:04Z",
    "name": "230518 Beromünster",
    "original_id": null,
    "timetype": "wander",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1773,
    "id": 190998111,
    "meta.biking": 426.1474457470878,
    "meta.snowshoe_trekking": 1494.8,
    "meta.walking": 996.5238378010297,
    "meta.winter_hiking": 1245.7,
    "modified_at": "2023-05-26T18:32:13Z",
    "name": "230526 St. Urban",
    "original_id": null,
    "timetype": "velo",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1774,
    "id": 931833390,
    "meta.biking": 82.20470603339773,
    "meta.snowshoe_trekking": 300.4,
    "meta.walking": 200.26595708842163,
    "meta.winter_hiking": 250.3,
    "modified_at": "2023-01-21T18:48:59Z",
    "name": "230121 Sursee Willisau",
    "original_id": null,
    "timetype": "wander",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1774,
    "id": 1354850008,
    "meta.biking": 86.66072445072052,
    "meta.snowshoe_trekking": 303.9,
    "meta.walking": 202.56771349211758,
    "meta.winter_hiking": 253.2,
    "modified_at": "2022-12-09T09:34:08Z",
    "name": "221208 Notwil Tannenfels",
    "original_id": null,
    "timetype": "wander",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1773,
    "id": 1753830913,
    "meta.biking": 356.2617440653954,
    "meta.snowshoe_trekking": 1192.7,
    "meta.walking": 795.1352925165081,
    "meta.winter_hiking": 993.9,
    "modified_at": "2022-11-06T20:30:37Z",
    "name": "221009 Luzern",
    "original_id": null,
    "timetype": "wander",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1774,
    "id": 1564524119,
    "meta.biking": 81.39853291093326,
    "meta.snowshoe_trekking": 228.4,
    "meta.walking": 152.25078744708037,
    "meta.winter_hiking": 190.3,
    "modified_at": "2022-11-06T20:04:20Z",
    "name": "221106 Wetzwil",
    "original_id": null,
    "timetype": "wander",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1773,
    "id": 1806548603,
    "meta.biking": 314.01258755704924,
    "meta.snowshoe_trekking": 1229.2,
    "meta.walking": 819.4533993469955,
    "meta.winter_hiking": 1024.3,
    "modified_at": "2022-11-01T14:59:29Z",
    "name": "221101 Turren Eiseesattel",
    "original_id": null,
    "timetype": "wander",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1773,
    "id": 1476639416,
    "meta.biking": 251.7567881781161,
    "meta.snowshoe_trekking": 860.7,
    "meta.walking": 573.8107010484396,
    "meta.winter_hiking": 717.3,
    "modified_at": "2022-10-30T18:59:46Z",
    "name": "221030 Vogelmoos",
    "original_id": null,
    "timetype": "wander",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1774,
    "id": 1132188073,
    "meta.biking": 116.6,
    "meta.snowshoe_trekking": 489.9,
    "meta.walking": 326.6,
    "meta.winter_hiking": 408.2,
    "modified_at": "2022-10-24T19:09:30Z",
    "name": "201218 Sempachersee",
    "original_id": null,
    "timetype": "wander",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1774,
    "id": 560889153,
    "meta.biking": 154.37525003959212,
    "meta.snowshoe_trekking": 504.8,
    "meta.walking": 336.5073685026753,
    "meta.winter_hiking": 420.6,
    "modified_at": "2022-10-22T20:25:54Z",
    "name": "221019 Schlierengrat",
    "original_id": null,
    "timetype": "wander",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1773,
    "id": 492557338,
    "meta.biking": 269.5541918138037,
    "meta.snowshoe_trekking": 976.8,
    "meta.walking": 651.2304129995781,
    "meta.winter_hiking": 814.0,
    "modified_at": "2022-09-11T16:00:38Z",
    "name": "220911 Sursee Triengen",
    "original_id": null,
    "timetype": "wander",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1773,
    "id": 1203432788,
    "meta.biking": 38.17780843575005,
    "meta.snowshoe_trekking": 287.7,
    "meta.walking": 191.81970962750202,
    "meta.winter_hiking": 239.8,
    "modified_at": "2022-08-07T11:59:05Z",
    "name": "170727 Scalottas Churwalden",
    "original_id": null,
    "timetype": "wander",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1773,
    "id": 1313315947,
    "meta.biking": 41.57263222999008,
    "meta.snowshoe_trekking": 269.9,
    "meta.walking": 179.9257372147537,
    "meta.winter_hiking": 224.9,
    "modified_at": "2022-08-07T11:48:18Z",
    "name": "200726 Schwarzsee Zermatt",
    "original_id": null,
    "timetype": "wander",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1773,
    "id": 1243383005,
    "meta.biking": 21.419524285070906,
    "meta.snowshoe_trekking": 280.5,
    "meta.walking": 186.99192080962905,
    "meta.winter_hiking": 233.7,
    "modified_at": "2022-08-07T11:46:53Z",
    "name": "200723 Gornergrat Zermatt",
    "original_id": null,
    "timetype": "wander",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1773,
    "id": 1886814114,
    "meta.biking": 27.050724076058614,
    "meta.snowshoe_trekking": 236.6,
    "meta.walking": 157.703554504832,
    "meta.winter_hiking": 197.1,
    "modified_at": "2022-08-07T11:44:31Z",
    "name": "200726 Blauherd Zermatt",
    "original_id": null,
    "timetype": "velo",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1773,
    "id": 1077675322,
    "meta.biking": 29.63534249969417,
    "meta.snowshoe_trekking": 260.1,
    "meta.walking": 173.4248838883905,
    "meta.winter_hiking": 216.8,
    "modified_at": "2022-08-07T11:42:50Z",
    "name": "170728 Rothorn Arosa",
    "original_id": null,
    "timetype": "wander",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1773,
    "id": 1415096878,
    "meta.biking": 144.15019044982148,
    "meta.snowshoe_trekking": 618.2,
    "meta.walking": 412.1023611636463,
    "meta.winter_hiking": 515.1,
    "modified_at": "2022-08-07T11:41:47Z",
    "name": "170729 Welschtobel",
    "original_id": null,
    "timetype": "wander",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1773,
    "id": 582939839,
    "meta.biking": 78.87746868438188,
    "meta.snowshoe_trekking": 424.0,
    "meta.walking": 282.6713223306743,
    "meta.winter_hiking": 353.3,
    "modified_at": "2022-08-07T11:40:54Z",
    "name": "180803 Jakobshorn Davos",
    "original_id": null,
    "timetype": "wander",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1773,
    "id": 1096824212,
    "meta.biking": 195.0374106011101,
    "meta.snowshoe_trekking": 729.1,
    "meta.walking": 486.0368378304833,
    "meta.winter_hiking": 607.5,
    "modified_at": "2022-08-07T10:18:21Z",
    "name": "220806 Col de Mille",
    "original_id": null,
    "timetype": "wander",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 94631,
    "id": 1983469023,
    "meta.biking": 226.9,
    "meta.snowshoe_trekking": 773.6,
    "meta.walking": 515.7,
    "meta.winter_hiking": 644.6,
    "modified_at": "2021-06-03T19:38:21Z",
    "name": "Bachiela",
    "original_id": null,
    "timetype": "wander",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1773,
    "id": 1041124486,
    "meta.biking": 56.25156431070168,
    "meta.snowshoe_trekking": 310.9,
    "meta.walking": 207.2745991732512,
    "meta.winter_hiking": 259.1,
    "modified_at": "2022-08-05T15:53:58Z",
    "name": "220804 Verbier",
    "original_id": null,
    "timetype": "wander",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1773,
    "id": 1280234339,
    "meta.biking": 29.748637157472007,
    "meta.snowshoe_trekking": 306.1,
    "meta.walking": 204.03648820210694,
    "meta.winter_hiking": 255.0,
    "modified_at": "2022-08-05T15:53:47Z",
    "name": "220804 Verbier",
    "original_id": null,
    "timetype": "wander",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1773,
    "id": 1078131877,
    "meta.biking": 40.306686057883226,
    "meta.snowshoe_trekking": 335.6,
    "meta.walking": 223.7380592576054,
    "meta.winter_hiking": 279.7,
    "modified_at": "2022-08-04T18:41:06Z",
    "name": "220804 Verbier",
    "original_id": null,
    "timetype": "wander",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1774,
    "id": 2131957483,
    "meta.biking": 76.0865757767,
    "meta.snowshoe_trekking": 157.9,
    "meta.walking": 105.2899982702,
    "meta.winter_hiking": 131.6,
    "modified_at": "2022-07-25T20:18:55Z",
    "name": "220725 Emmetten Stockhütte",
    "original_id": null,
    "timetype": "wander",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1779,
    "id": 822888394,
    "meta.biking": 48.8680736213,
    "meta.snowshoe_trekking": 245.9,
    "meta.walking": 163.9332968802,
    "meta.winter_hiking": 204.9,
    "modified_at": "2022-07-24T19:01:31Z",
    "name": "0????? Tamins Chur",
    "original_id": null,
    "timetype": "wander",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1779,
    "id": 718223933,
    "meta.biking": 459.8535416253,
    "meta.snowshoe_trekking": 2336.5,
    "meta.walking": 1557.6428761473,
    "meta.winter_hiking": 1947.1,
    "modified_at": "2022-07-24T19:01:15Z",
    "name": "0????? Altstätten Stein am Rhein",
    "original_id": null,
    "timetype": "wander",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1779,
    "id": 240286201,
    "meta.biking": 382.1972212181,
    "meta.snowshoe_trekking": 1888.8,
    "meta.walking": 1259.2121244187,
    "meta.winter_hiking": 1574.0,
    "modified_at": "2022-07-24T19:01:03Z",
    "name": "0????? Chur Altstätten",
    "original_id": null,
    "timetype": "velo",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1779,
    "id": 43958755,
    "meta.biking": 239.9803820404,
    "meta.snowshoe_trekking": 1272.6,
    "meta.walking": 848.4065801265,
    "meta.winter_hiking": 1060.5,
    "modified_at": "2022-07-24T19:00:49Z",
    "name": "0????? Oberalp Valendas",
    "original_id": null,
    "timetype": "wander",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1779,
    "id": 865425507,
    "meta.biking": 98.5934206827,
    "meta.snowshoe_trekking": 383.9,
    "meta.walking": 255.948799761,
    "meta.winter_hiking": 319.9,
    "modified_at": "2022-07-24T19:00:39Z",
    "name": "0????? Valendas Tamins",
    "original_id": null,
    "timetype": "wander",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1779,
    "id": 424048021,
    "meta.biking": 251.088939571,
    "meta.snowshoe_trekking": 1116.4,
    "meta.walking": 744.2385383184,
    "meta.winter_hiking": 930.3,
    "modified_at": "2022-07-24T19:00:17Z",
    "name": "0????? Stein am Rhein Winterthur",
    "original_id": null,
    "timetype": "wander",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1779,
    "id": 848836570,
    "meta.biking": 324.2100625779,
    "meta.snowshoe_trekking": 1016.0,
    "meta.walking": 677.3248227353,
    "meta.winter_hiking": 846.7,
    "modified_at": "2022-07-24T18:33:06Z",
    "name": "070717 Ste Croix Les Charbonnières",
    "original_id": null,
    "timetype": "wander",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1779,
    "id": 1057302921,
    "meta.biking": 665.3325037313,
    "meta.snowshoe_trekking": 2139.7,
    "meta.walking": 1426.4457575334,
    "meta.winter_hiking": 1783.1,
    "modified_at": "2022-07-24T18:32:57Z",
    "name": "070716 Basel Le Bémont",
    "original_id": null,
    "timetype": "wander",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1779,
    "id": 147662371,
    "meta.biking": 644.5179743397,
    "meta.snowshoe_trekking": 2299.9,
    "meta.walking": 1533.2590202862,
    "meta.winter_hiking": 1916.6,
    "modified_at": "2022-07-24T18:32:46Z",
    "name": "070716 Le Bémont Ste Croix",
    "original_id": null,
    "timetype": "wander",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1779,
    "id": 1079713474,
    "meta.biking": 245.7892266567,
    "meta.snowshoe_trekking": 1095.4,
    "meta.walking": 730.2892958452,
    "meta.winter_hiking": 912.9,
    "modified_at": "2022-07-24T18:32:27Z",
    "name": "070718 Les Charbonnières Nyon",
    "original_id": null,
    "timetype": "wander",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1779,
    "id": 1918634949,
    "meta.biking": 380.3809797795,
    "meta.snowshoe_trekking": 1696.8,
    "meta.walking": 1131.2005286848,
    "meta.winter_hiking": 1414.0,
    "modified_at": "2022-07-24T18:16:57Z",
    "name": "070715 Triengen Basel",
    "original_id": null,
    "timetype": "wander",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1773,
    "id": 817288427,
    "meta.biking": 199.4224540785,
    "meta.snowshoe_trekking": 555.8,
    "meta.walking": 370.5597409161,
    "meta.winter_hiking": 463.2,
    "modified_at": "2022-07-24T11:21:57Z",
    "name": "180731 Emmetten Panorama Tour",
    "original_id": null,
    "timetype": "wander",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 79387,
    "id": 1633314391,
    "meta.biking": 113.6,
    "meta.snowshoe_trekking": 430.3,
    "meta.walking": 286.9,
    "meta.winter_hiking": 358.6,
    "modified_at": "2022-07-17T19:23:58Z",
    "name": "220212 Stotzige Firsten",
    "original_id": null,
    "timetype": "wander",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1779,
    "id": 13610697,
    "meta.biking": 394.6334967364,
    "meta.snowshoe_trekking": 1081.6,
    "meta.walking": 721.0996750021,
    "meta.winter_hiking": 901.4,
    "modified_at": "2022-07-17T19:18:25Z",
    "name": "041005 Gurtnellen Disentis",
    "original_id": null,
    "timetype": "wander",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1779,
    "id": 686037120,
    "meta.biking": 602.3789858902,
    "meta.snowshoe_trekking": 2404.4,
    "meta.walking": 1602.9029023398,
    "meta.winter_hiking": 2003.6,
    "modified_at": "2022-07-17T19:17:39Z",
    "name": "041004 Triengen Gurtnellen",
    "original_id": null,
    "timetype": "wander",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1779,
    "id": 732816590,
    "meta.biking": 445.5006657691,
    "meta.snowshoe_trekking": 1838.2,
    "meta.walking": 1225.4474881761,
    "meta.winter_hiking": 1531.8,
    "modified_at": "2022-07-17T19:15:50Z",
    "name": "041006 Disentis Bellinzona",
    "original_id": null,
    "timetype": "wander",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1774,
    "id": 1832780,
    "meta.biking": 75.6,
    "meta.snowshoe_trekking": 304.4,
    "meta.walking": 202.9,
    "meta.winter_hiking": 253.6,
    "modified_at": "2020-04-29T15:44:41Z",
    "name": "130802 Muttseehütte",
    "original_id": null,
    "timetype": "wander",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1774,
    "id": 6720353,
    "meta.biking": 90.2,
    "meta.snowshoe_trekking": 295.0,
    "meta.walking": 196.7,
    "meta.winter_hiking": 245.9,
    "modified_at": "2021-10-12T12:40:26Z",
    "name": "200429 Eich Sempach",
    "original_id": null,
    "timetype": "wander",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1774,
    "id": 2085706,
    "meta.biking": 51.5,
    "meta.snowshoe_trekking": 213.6,
    "meta.walking": 142.4,
    "meta.winter_hiking": 178.0,
    "modified_at": "2020-04-29T15:47:17Z",
    "name": "150525 Pilatus, Teil 1",
    "original_id": null,
    "timetype": "wander",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1774,
    "id": 2085714,
    "meta.biking": 33.6,
    "meta.snowshoe_trekking": 193.2,
    "meta.walking": 128.8,
    "meta.winter_hiking": 161.0,
    "modified_at": "2020-04-29T15:47:08Z",
    "name": "150525 Pilatus, Teil 2",
    "original_id": null,
    "timetype": "wander",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1773,
    "id": 5540913,
    "meta.biking": 312.6,
    "meta.snowshoe_trekking": 1087.1,
    "meta.walking": 724.7,
    "meta.winter_hiking": 905.9,
    "modified_at": "2020-04-29T15:52:24Z",
    "name": "190531 Ettiswil Santenberg",
    "original_id": null,
    "timetype": "velo",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1774,
    "id": 7358849,
    "meta.biking": 130.9,
    "meta.snowshoe_trekking": 324.8,
    "meta.walking": 216.5,
    "meta.winter_hiking": 270.6,
    "modified_at": "2020-08-26T19:51:18Z",
    "name": "200707 Realp Garschigsee",
    "original_id": null,
    "timetype": "wander",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1774,
    "id": 3981297,
    "meta.biking": 94.81213513320793,
    "meta.snowshoe_trekking": 377.8,
    "meta.walking": 251.88873113573305,
    "meta.winter_hiking": 314.9,
    "modified_at": "2020-04-29T15:50:25Z",
    "name": "171008 Entlebuch Albliegg",
    "original_id": null,
    "timetype": "wander",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1774,
    "id": 3812317,
    "meta.biking": 101.57265259006213,
    "meta.snowshoe_trekking": 480.7,
    "meta.walking": 320.45267389214405,
    "meta.winter_hiking": 400.6,
    "modified_at": "2020-04-29T15:48:52Z",
    "name": "170815 Goldau Sattel",
    "original_id": null,
    "timetype": "wander",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1773,
    "id": 2075892511,
    "meta.biking": 295.2,
    "meta.snowshoe_trekking": 1210.2,
    "meta.walking": 806.8,
    "meta.winter_hiking": 1008.5,
    "modified_at": "2020-09-03T16:25:50Z",
    "name": "200903 Turren Eiseesattel",
    "original_id": null,
    "timetype": "wander",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1774,
    "id": 2427175,
    "meta.biking": 64.28577226269911,
    "meta.snowshoe_trekking": 314.1,
    "meta.walking": 209.390867308114,
    "meta.winter_hiking": 261.7,
    "modified_at": "2020-04-29T15:49:38Z",
    "name": "151024 Saignelegier Tramelan",
    "original_id": null,
    "timetype": "wander",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1773,
    "id": 1851716,
    "meta.biking": 210.2,
    "meta.snowshoe_trekking": 715.0,
    "meta.walking": 476.7,
    "meta.winter_hiking": 595.9,
    "modified_at": "2020-04-29T15:45:24Z",
    "name": "140923 Rigi",
    "original_id": null,
    "timetype": "velo",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1774,
    "id": 2085723,
    "meta.biking": 101.1,
    "meta.snowshoe_trekking": 500.4,
    "meta.walking": 333.6,
    "meta.winter_hiking": 417.0,
    "modified_at": "2020-04-29T15:49:28Z",
    "name": "150525 Pilatus",
    "original_id": null,
    "timetype": "wander",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1774,
    "id": 1877110,
    "meta.biking": 159.3,
    "meta.snowshoe_trekking": 455.5,
    "meta.walking": 303.7,
    "meta.winter_hiking": 379.6,
    "modified_at": "2020-04-29T15:45:13Z",
    "name": "141002 Niesen",
    "original_id": null,
    "timetype": "wander",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1774,
    "id": 7358881,
    "meta.biking": 127.3,
    "meta.snowshoe_trekking": 547.0,
    "meta.walking": 364.7,
    "meta.winter_hiking": 455.9,
    "modified_at": "2021-10-12T12:40:26Z",
    "name": "200708 Garschigsee Rotondo Realp",
    "original_id": null,
    "timetype": "wander",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1774,
    "id": 4999219,
    "meta.biking": 171.7297129209,
    "meta.snowshoe_trekking": 617.2,
    "meta.walking": 411.4367827078,
    "meta.winter_hiking": 514.3,
    "modified_at": "2020-04-29T15:52:17Z",
    "name": "181005 Schrattenflueh",
    "original_id": null,
    "timetype": "wander",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1773,
    "id": 3988323,
    "meta.biking": 173.8402380199153,
    "meta.snowshoe_trekking": 790.9,
    "meta.walking": 527.2850265428904,
    "meta.winter_hiking": 659.1,
    "modified_at": "2020-04-29T15:50:55Z",
    "name": "171011 Sursee Neudorf Rickenbach",
    "original_id": null,
    "timetype": "velo",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1773,
    "id": 3957752,
    "meta.biking": 253.88335215227377,
    "meta.snowshoe_trekking": 1171.3,
    "meta.walking": 780.8542802475744,
    "meta.winter_hiking": 976.1,
    "modified_at": "2020-04-29T15:50:16Z",
    "name": "171001 Engelberg Sarnen",
    "original_id": null,
    "timetype": "velo",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1779,
    "id": 1832784,
    "meta.biking": 203.34819814565645,
    "meta.snowshoe_trekking": 639.7,
    "meta.walking": 426.45223182989184,
    "meta.winter_hiking": 533.1,
    "modified_at": "2020-04-29T15:49:17Z",
    "name": "140904 Kottwil Arig Nottwil",
    "original_id": null,
    "timetype": "wander",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1774,
    "id": 1832768,
    "meta.biking": 122.8,
    "meta.snowshoe_trekking": 602.5,
    "meta.walking": 401.7,
    "meta.winter_hiking": 502.1,
    "modified_at": "2020-04-29T15:49:05Z",
    "name": "140806 Finsterwald Schimbrig",
    "original_id": null,
    "timetype": "wander",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1774,
    "id": 1877120,
    "meta.biking": 47.4,
    "meta.snowshoe_trekking": 277.2,
    "meta.walking": 184.8,
    "meta.winter_hiking": 231.0,
    "modified_at": "2020-04-29T15:45:05Z",
    "name": "130818 Vitznauerstock",
    "original_id": null,
    "timetype": "wander",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1774,
    "id": 1832773,
    "meta.biking": 147.9,
    "meta.snowshoe_trekking": 657.0,
    "meta.walking": 438.0,
    "meta.winter_hiking": 547.5,
    "modified_at": "2020-04-29T15:44:48Z",
    "name": "130807 Schluchberg",
    "original_id": null,
    "timetype": "wander",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1773,
    "id": 6236676,
    "meta.biking": 224.9,
    "meta.snowshoe_trekking": 761.4,
    "meta.walking": 507.6,
    "meta.winter_hiking": 634.5,
    "modified_at": "2020-04-29T15:42:30Z",
    "name": "191027 Sempachersee",
    "original_id": null,
    "timetype": "no",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1774,
    "id": 6694445,
    "meta.biking": 107.8,
    "meta.snowshoe_trekking": 299.0,
    "meta.walking": 199.3,
    "meta.winter_hiking": 249.1,
    "modified_at": "2021-10-12T12:40:26Z",
    "name": "200424 Gschweich",
    "original_id": null,
    "timetype": "wander",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1774,
    "id": 6630442,
    "meta.biking": 89.6,
    "meta.snowshoe_trekking": 267.3,
    "meta.walking": 178.2,
    "meta.winter_hiking": 222.8,
    "modified_at": "2020-04-16T14:09:26Z",
    "name": "200416 Walbert Tobel",
    "original_id": null,
    "timetype": "wander",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1774,
    "id": 1099062996,
    "meta.biking": 58.9539305045,
    "meta.snowshoe_trekking": 202.5,
    "meta.walking": 135.029076074,
    "meta.winter_hiking": 168.8,
    "modified_at": "2022-07-02T19:23:39Z",
    "name": "210529 Schwarzenbach Mosen",
    "original_id": null,
    "timetype": "wander",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1773,
    "id": 1930885063,
    "meta.biking": 481.6729112243,
    "meta.snowshoe_trekking": 1616.2,
    "meta.walking": 1077.4817079297,
    "meta.winter_hiking": 1346.9,
    "modified_at": "2022-07-02T19:13:33Z",
    "name": "220702 Horben",
    "original_id": null,
    "timetype": "wander",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1773,
    "id": 152125131,
    "meta.biking": 282.3799866486,
    "meta.snowshoe_trekking": 1000.9,
    "meta.walking": 667.2809751373,
    "meta.winter_hiking": 834.1,
    "modified_at": "2022-07-01T18:30:16Z",
    "name": "220626 Sempachersee",
    "original_id": null,
    "timetype": "wander",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1773,
    "id": 3938694,
    "meta.biking": 195.5095429776347,
    "meta.snowshoe_trekking": 785.9,
    "meta.walking": 523.9604593397198,
    "meta.winter_hiking": 655.0,
    "modified_at": "2020-04-29T15:50:08Z",
    "name": "170924 Renggpass Mueterschwandenberg",
    "original_id": null,
    "timetype": "velo",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1774,
    "id": 423927930,
    "meta.biking": 31.8,
    "meta.snowshoe_trekking": 108.3,
    "meta.walking": 72.2,
    "meta.winter_hiking": 90.2,
    "modified_at": "2022-06-17T17:13:59Z",
    "name": "220616 Fritzeflue",
    "original_id": null,
    "timetype": "wander",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1774,
    "id": 708334949,
    "meta.biking": 45.9,
    "meta.snowshoe_trekking": 155.9,
    "meta.walking": 103.9,
    "meta.winter_hiking": 129.9,
    "modified_at": "2022-06-17T17:12:07Z",
    "name": "220617 Zyberliland Romos",
    "original_id": null,
    "timetype": "wander",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1774,
    "id": 1341627322,
    "meta.biking": 44.9,
    "meta.snowshoe_trekking": 163.2,
    "meta.walking": 108.8,
    "meta.winter_hiking": 136.0,
    "modified_at": "2021-12-30T22:54:51Z",
    "name": "211230 Wohlensee",
    "original_id": null,
    "timetype": "wander",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1774,
    "id": 601055698,
    "meta.biking": 68.5,
    "meta.snowshoe_trekking": 258.8,
    "meta.walking": 172.5,
    "meta.winter_hiking": 215.6,
    "modified_at": "2021-11-06T17:22:00Z",
    "name": "211106 Axalp",
    "original_id": null,
    "timetype": "wander",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1774,
    "id": 123174054,
    "meta.biking": 223.3,
    "meta.snowshoe_trekking": 852.4,
    "meta.walking": 568.3,
    "meta.winter_hiking": 710.4,
    "modified_at": "2020-09-16T15:40:06Z",
    "name": "200916 Axalp Faulhorn",
    "original_id": null,
    "timetype": "wander",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1773,
    "id": 849640087,
    "meta.biking": 231.4,
    "meta.snowshoe_trekking": 807.9,
    "meta.walking": 538.6,
    "meta.winter_hiking": 673.2,
    "modified_at": "2021-10-17T19:19:02Z",
    "name": "211017 Grindelwald First",
    "original_id": null,
    "timetype": "velo",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1773,
    "id": 390888146,
    "meta.biking": 135.6,
    "meta.snowshoe_trekking": 621.9,
    "meta.walking": 414.6,
    "meta.winter_hiking": 518.2,
    "modified_at": "2021-10-29T15:36:22Z",
    "name": "211029 Fürenalp Erstfeld",
    "original_id": null,
    "timetype": "wander",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1774,
    "id": 5937082,
    "meta.biking": 194.6,
    "meta.snowshoe_trekking": 684.3,
    "meta.walking": 456.2,
    "meta.winter_hiking": 570.2,
    "modified_at": "2019-09-30T09:12:28Z",
    "name": "190817 Sörenberg Höch Gumme Rothorn",
    "original_id": null,
    "timetype": "wander",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1773,
    "id": 6544788,
    "meta.biking": 414.4,
    "meta.snowshoe_trekking": 1294.2,
    "meta.walking": 862.8,
    "meta.winter_hiking": 1078.5,
    "modified_at": "2020-04-13T17:23:52Z",
    "name": "200403 Böhler Wiliberg",
    "original_id": null,
    "timetype": "velo",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1773,
    "id": 6614295,
    "meta.biking": 321.5,
    "meta.snowshoe_trekking": 991.8,
    "meta.walking": 661.2,
    "meta.winter_hiking": 826.5,
    "modified_at": "2020-04-13T17:24:36Z",
    "name": "200413 Hildisrieden Vogelmoos",
    "original_id": null,
    "timetype": "velo",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1774,
    "id": 6626656,
    "meta.biking": 126.6,
    "meta.snowshoe_trekking": 377.4,
    "meta.walking": 251.6,
    "meta.winter_hiking": 314.5,
    "modified_at": "2021-10-12T12:40:26Z",
    "name": "200415 Lätte",
    "original_id": null,
    "timetype": "wander",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1773,
    "id": 6684572,
    "meta.biking": 464.5,
    "meta.snowshoe_trekking": 1528.5,
    "meta.walking": 1019.0,
    "meta.winter_hiking": 1273.8,
    "modified_at": "2020-04-23T20:31:50Z",
    "name": "200423 Gschweich Lätte",
    "original_id": null,
    "timetype": "velo",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1774,
    "id": 6694401,
    "meta.biking": 86.3,
    "meta.snowshoe_trekking": 224.5,
    "meta.walking": 149.7,
    "meta.winter_hiking": 187.1,
    "modified_at": "2020-04-25T09:08:18Z",
    "name": "200417 Büron Krumbach",
    "original_id": null,
    "timetype": "wander",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1774,
    "id": 6707495,
    "meta.biking": 200.0,
    "meta.snowshoe_trekking": 645.5,
    "meta.walking": 430.3,
    "meta.winter_hiking": 537.9,
    "modified_at": "2020-04-27T16:29:54Z",
    "name": "200427 Ahorn Napf",
    "original_id": null,
    "timetype": "wander",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1774,
    "id": 5805718,
    "meta.biking": 116.8,
    "meta.snowshoe_trekking": 444.0,
    "meta.walking": 296.0,
    "meta.winter_hiking": 370.0,
    "modified_at": "2020-04-29T15:42:04Z",
    "name": "190727 Buochserhorn Musenalp",
    "original_id": null,
    "timetype": "wander",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1774,
    "id": 6137461,
    "meta.biking": 143.8,
    "meta.snowshoe_trekking": 662.8,
    "meta.walking": 441.9,
    "meta.winter_hiking": 552.4,
    "modified_at": "2020-04-29T15:43:03Z",
    "name": "190929 Uri Rotstock",
    "original_id": null,
    "timetype": "wander",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1773,
    "id": 5539613,
    "meta.biking": 398.2,
    "meta.snowshoe_trekking": 1208.1,
    "meta.walking": 805.4,
    "meta.winter_hiking": 1006.8,
    "modified_at": "2020-04-29T15:43:19Z",
    "name": "190602 Renggpass Lütholdsmatt",
    "original_id": null,
    "timetype": "no",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1774,
    "id": 2953824,
    "meta.biking": 174.1,
    "meta.snowshoe_trekking": 460.2,
    "meta.walking": 306.8,
    "meta.winter_hiking": 383.5,
    "modified_at": "2020-04-29T15:44:29Z",
    "name": "130722 Brünig Rothorn",
    "original_id": null,
    "timetype": "wander",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1774,
    "id": 2184445,
    "meta.biking": 195.056266229,
    "meta.snowshoe_trekking": 539.0,
    "meta.walking": 359.314444846,
    "meta.winter_hiking": 449.1,
    "modified_at": "2020-04-29T15:47:42Z",
    "name": "150712 Eggberge Klausenpass",
    "original_id": null,
    "timetype": "wander",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1774,
    "id": 2953849,
    "meta.biking": 205.927870026,
    "meta.snowshoe_trekking": 537.4,
    "meta.walking": 358.246457352,
    "meta.winter_hiking": 447.8,
    "modified_at": "2020-04-29T15:47:52Z",
    "name": "150731 Maderanertal",
    "original_id": null,
    "timetype": "wander",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1773,
    "id": 3678936,
    "meta.biking": 266.13133214073287,
    "meta.snowshoe_trekking": 980.1,
    "meta.walking": 653.402901110425,
    "meta.winter_hiking": 816.8,
    "modified_at": "2020-04-29T15:48:42Z",
    "name": "170716 Fräkmünt Renggpass",
    "original_id": null,
    "timetype": "velo",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1773,
    "id": 3812359,
    "meta.biking": 274.46280710315614,
    "meta.snowshoe_trekking": 964.7,
    "meta.walking": 643.1541900842021,
    "meta.winter_hiking": 803.9,
    "modified_at": "2020-04-29T15:49:54Z",
    "name": "170815 Krienseregg Alp-Gschwänd",
    "original_id": null,
    "timetype": "wander",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1774,
    "id": 3988258,
    "meta.biking": 126.96447717671838,
    "meta.snowshoe_trekking": 485.9,
    "meta.walking": 323.94982240192485,
    "meta.winter_hiking": 404.9,
    "modified_at": "2020-04-29T15:50:41Z",
    "name": "171010 Haglere",
    "original_id": null,
    "timetype": "wander",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1773,
    "id": 4396961,
    "meta.biking": 344.8934962747,
    "meta.snowshoe_trekking": 1018.8,
    "meta.walking": 679.2045036916,
    "meta.winter_hiking": 849.0,
    "modified_at": "2020-04-29T15:51:28Z",
    "name": "180521 Eigental Hüenerhubel",
    "original_id": null,
    "timetype": "velo",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1773,
    "id": 4494757,
    "meta.biking": 439.4746322917,
    "meta.snowshoe_trekking": 1343.1,
    "meta.walking": 895.4215137159,
    "meta.winter_hiking": 1119.3,
    "modified_at": "2020-04-29T15:51:38Z",
    "name": "180616 Rigi Luzern",
    "original_id": null,
    "timetype": "velo",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1773,
    "id": 4494705,
    "meta.biking": 235.1792426749,
    "meta.snowshoe_trekking": 1056.1,
    "meta.walking": 704.0886588422,
    "meta.winter_hiking": 880.1,
    "modified_at": "2020-04-29T15:51:48Z",
    "name": "180617 Brunni Luzern",
    "original_id": null,
    "timetype": "velo",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1774,
    "id": 4659866,
    "meta.biking": 125.4805666444,
    "meta.snowshoe_trekking": 466.6,
    "meta.walking": 311.0901998234,
    "meta.winter_hiking": 388.9,
    "modified_at": "2020-04-29T15:51:55Z",
    "name": "180723 Göscheneralpsee",
    "original_id": null,
    "timetype": "wander",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1774,
    "id": 4922199,
    "meta.biking": 79.1545638834,
    "meta.snowshoe_trekking": 399.2,
    "meta.walking": 266.1317737703,
    "meta.winter_hiking": 332.7,
    "modified_at": "2020-04-29T15:52:04Z",
    "name": "180912 Rophaien",
    "original_id": null,
    "timetype": "wander",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1774,
    "id": 4928170,
    "meta.biking": 131.1657593109,
    "meta.snowshoe_trekking": 556.5,
    "meta.walking": 371.0096058519,
    "meta.winter_hiking": 463.8,
    "modified_at": "2020-04-29T15:52:11Z",
    "name": "180916 Brisen",
    "original_id": null,
    "timetype": "wander",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1774,
    "id": 6744690,
    "meta.biking": 107.5,
    "meta.snowshoe_trekking": 344.4,
    "meta.walking": 229.6,
    "meta.winter_hiking": 287.0,
    "modified_at": "2020-05-03T18:18:14Z",
    "name": "200503 Soppisee Ostergau",
    "original_id": null,
    "timetype": "wander",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1773,
    "id": 6767836,
    "meta.biking": 287.9,
    "meta.snowshoe_trekking": 789.9,
    "meta.walking": 526.6,
    "meta.winter_hiking": 658.2,
    "modified_at": "2021-10-12T12:40:26Z",
    "name": "200507 Rigi",
    "original_id": null,
    "timetype": "velo",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1773,
    "id": 6900795,
    "meta.biking": 218.1,
    "meta.snowshoe_trekking": 595.7,
    "meta.walking": 397.1,
    "meta.winter_hiking": 496.4,
    "modified_at": "2021-10-12T12:40:26Z",
    "name": "200521 Marbach",
    "original_id": null,
    "timetype": "velo",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1774,
    "id": 6974850,
    "meta.biking": 159.6,
    "meta.snowshoe_trekking": 568.5,
    "meta.walking": 379.0,
    "meta.winter_hiking": 473.8,
    "modified_at": "2021-10-12T12:40:26Z",
    "name": "200529 Eigental Mittaggüpfi",
    "original_id": null,
    "timetype": "wander",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1774,
    "id": 7330026,
    "meta.biking": 240.2,
    "meta.snowshoe_trekking": 818.6,
    "meta.walking": 545.7,
    "meta.winter_hiking": 682.1,
    "modified_at": "2020-07-08T18:21:40Z",
    "name": "200707 Realp Rotondo",
    "original_id": null,
    "timetype": "wander",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1779,
    "id": 7589279,
    "meta.biking": 448.0,
    "meta.snowshoe_trekking": 1378.8,
    "meta.walking": 919.2,
    "meta.winter_hiking": 1149.0,
    "modified_at": "2020-07-29T10:35:34Z",
    "name": "200728 Sursee Langnau",
    "original_id": null,
    "timetype": "velo",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1779,
    "id": 7599928,
    "meta.biking": 304.9,
    "meta.snowshoe_trekking": 858.8,
    "meta.walking": 572.5,
    "meta.winter_hiking": 715.6,
    "modified_at": "2020-07-29T11:32:37Z",
    "name": "200729 Langnau Schüpfheim",
    "original_id": null,
    "timetype": "wander",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1779,
    "id": 7600532,
    "meta.biking": 401.3,
    "meta.snowshoe_trekking": 1393.8,
    "meta.walking": 929.2,
    "meta.winter_hiking": 1161.5,
    "modified_at": "2020-07-30T13:01:21Z",
    "name": "200730 Schüpfheim Sursee",
    "original_id": null,
    "timetype": "velo",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1773,
    "id": 7821819,
    "meta.biking": 257.9,
    "meta.snowshoe_trekking": 1001.4,
    "meta.walking": 667.6,
    "meta.winter_hiking": 834.5,
    "modified_at": "2021-10-12T12:40:26Z",
    "name": "200820 Jochpass Giswil",
    "original_id": null,
    "timetype": "wander",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1774,
    "id": 7330063,
    "meta.biking": 96.8,
    "meta.snowshoe_trekking": 351.9,
    "meta.walking": 234.6,
    "meta.winter_hiking": 293.2,
    "modified_at": "2020-08-21T13:55:26Z",
    "name": "200821 Gotthard Rundwanderung",
    "original_id": null,
    "timetype": "wander",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1774,
    "id": 7867115,
    "meta.biking": 139.9,
    "meta.snowshoe_trekking": 609.5,
    "meta.walking": 406.3,
    "meta.winter_hiking": 507.9,
    "modified_at": "2020-08-26T19:43:54Z",
    "name": "200826 Ebenalp Meglisalp Wasserauen",
    "original_id": null,
    "timetype": "wander",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1773,
    "id": 1078831370,
    "meta.biking": 443.4,
    "meta.snowshoe_trekking": 1317.4,
    "meta.walking": 878.3,
    "meta.winter_hiking": 1097.9,
    "modified_at": "2020-09-09T15:01:57Z",
    "name": "200909 Brunnen Chinzig Flüelen",
    "original_id": null,
    "timetype": "velo",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 72038,
    "id": 7398480,
    "meta.biking": 319.2,
    "meta.snowshoe_trekking": 1196.4,
    "meta.walking": 797.6,
    "meta.winter_hiking": 997.0,
    "modified_at": "2020-09-16T15:38:58Z",
    "name": "Susanne",
    "original_id": null,
    "timetype": "wander",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 72038,
    "id": 7413393,
    "meta.biking": 187.1,
    "meta.snowshoe_trekking": 621.9,
    "meta.walking": 414.6,
    "meta.winter_hiking": 518.2,
    "modified_at": "2020-09-16T15:39:12Z",
    "name": "Susanne II",
    "original_id": null,
    "timetype": "wander",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 72038,
    "id": 7413597,
    "meta.biking": 252.3,
    "meta.snowshoe_trekking": 874.7,
    "meta.walking": 583.1,
    "meta.winter_hiking": 728.9,
    "modified_at": "2020-09-16T15:39:20Z",
    "name": "Susanne II mit Piz.gletscher",
    "original_id": null,
    "timetype": "wander",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1774,
    "id": 2108188329,
    "meta.biking": 101.4,
    "meta.snowshoe_trekking": 394.7,
    "meta.walking": 263.1,
    "meta.winter_hiking": 328.9,
    "modified_at": "2021-10-12T12:40:26Z",
    "name": "201018 Staffelegg Brugg",
    "original_id": null,
    "timetype": "wander",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1774,
    "id": 413594081,
    "meta.biking": 69.8,
    "meta.snowshoe_trekking": 309.1,
    "meta.walking": 206.1,
    "meta.winter_hiking": 257.6,
    "modified_at": "2020-11-18T17:42:12Z",
    "name": "201118 Fürstein",
    "original_id": null,
    "timetype": "wander",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1773,
    "id": 32390169,
    "meta.biking": 317.8,
    "meta.snowshoe_trekking": 964.5,
    "meta.walking": 643.0,
    "meta.winter_hiking": 803.8,
    "modified_at": "2020-10-19T14:20:15Z",
    "name": "201019 Olten Froburg Homberg",
    "original_id": null,
    "timetype": "velo",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1773,
    "id": 5630535,
    "meta.biking": 356.0,
    "meta.snowshoe_trekking": 956.4,
    "meta.walking": 637.6,
    "meta.winter_hiking": 797.0,
    "modified_at": "2020-04-29T15:43:29Z",
    "name": "190623 Eigental Trochematt",
    "original_id": null,
    "timetype": "wander",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1774,
    "id": 5862976,
    "meta.biking": 149.2,
    "meta.snowshoe_trekking": 356.7,
    "meta.walking": 237.8,
    "meta.winter_hiking": 297.2,
    "modified_at": "2020-04-29T15:42:18Z",
    "name": "190804 Grafenort Engelberg",
    "original_id": null,
    "timetype": "wander",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1773,
    "id": 6720486,
    "meta.biking": 604.3,
    "meta.snowshoe_trekking": 1771.6,
    "meta.walking": 1181.1,
    "meta.winter_hiking": 1476.4,
    "modified_at": "2020-04-29T16:11:55Z",
    "name": "191014 Sursee Napf",
    "original_id": null,
    "timetype": "wander",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1774,
    "id": 2953801,
    "meta.biking": 89.7297366624,
    "meta.snowshoe_trekking": 279.9,
    "meta.walking": 186.567725525,
    "meta.winter_hiking": 233.2,
    "modified_at": "2020-04-29T15:48:34Z",
    "name": "160816 Hochänzi",
    "original_id": null,
    "timetype": "wander",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1774,
    "id": 3981311,
    "meta.biking": 91.17922560446733,
    "meta.snowshoe_trekking": 386.2,
    "meta.walking": 257.48268289161416,
    "meta.winter_hiking": 321.9,
    "modified_at": "2020-04-29T15:50:32Z",
    "name": "171009 Oberalppass Tomasee",
    "original_id": null,
    "timetype": "wander",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1773,
    "id": 6773803,
    "meta.biking": 244.7,
    "meta.snowshoe_trekking": 724.2,
    "meta.walking": 482.8,
    "meta.winter_hiking": 603.5,
    "modified_at": "2020-05-08T17:15:49Z",
    "name": "200508 Amsteg Golzerensee",
    "original_id": null,
    "timetype": "velo",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1773,
    "id": 4363407,
    "meta.biking": 476.59050782308174,
    "meta.snowshoe_trekking": 1489.5,
    "meta.walking": 993.0311623081493,
    "meta.winter_hiking": 1241.3,
    "modified_at": "2020-04-29T15:51:14Z",
    "name": "180511 Sursee St.Urban",
    "original_id": null,
    "timetype": "velo",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1774,
    "id": 4017003,
    "meta.biking": 203.34675886574902,
    "meta.snowshoe_trekking": 851.4,
    "meta.walking": 567.629166390387,
    "meta.winter_hiking": 709.5,
    "modified_at": "2020-04-29T15:51:03Z",
    "name": "171018 Gfellen Pilatus Alpnach",
    "original_id": null,
    "timetype": "wander",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1774,
    "id": 2659146,
    "meta.biking": 46.1001297277,
    "meta.snowshoe_trekking": 187.6,
    "meta.walking": 125.042882702,
    "meta.winter_hiking": 156.3,
    "modified_at": "2020-04-29T15:48:25Z",
    "name": "160507 Grächen Hannigalp",
    "original_id": null,
    "timetype": "wander",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1774,
    "id": 2659132,
    "meta.biking": 71.7495750322,
    "meta.snowshoe_trekking": 285.3,
    "meta.walking": 190.181249271,
    "meta.winter_hiking": 237.7,
    "modified_at": "2020-04-29T15:48:19Z",
    "name": "160506 Grächen Gletscher",
    "original_id": null,
    "timetype": "wander",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1774,
    "id": 2427183,
    "meta.biking": 57.2813362451,
    "meta.snowshoe_trekking": 167.5,
    "meta.walking": 111.643607706,
    "meta.winter_hiking": 139.6,
    "modified_at": "2020-04-29T15:48:12Z",
    "name": "151101 Niederrickenbach Musenalp",
    "original_id": null,
    "timetype": "wander",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1774,
    "id": 1925485379,
    "meta.biking": 121.4,
    "meta.snowshoe_trekking": 434.1,
    "meta.walking": 289.4,
    "meta.winter_hiking": 361.8,
    "modified_at": "2021-06-27T08:04:54Z",
    "name": "210626 Schratteflue",
    "original_id": null,
    "timetype": "wander",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1773,
    "id": 7146528,
    "meta.biking": 407.6,
    "meta.snowshoe_trekking": 1443.8,
    "meta.walking": 962.5,
    "meta.winter_hiking": 1203.1,
    "modified_at": "2021-07-22T16:00:17Z",
    "name": "210716 Postautotour Umbrail Poschiavo",
    "original_id": null,
    "timetype": "no",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1773,
    "id": 7146510,
    "meta.biking": 277.7,
    "meta.snowshoe_trekking": 735.2,
    "meta.walking": 490.1,
    "meta.winter_hiking": 612.6,
    "modified_at": "2021-07-22T16:00:34Z",
    "name": "210715 Postautotour Scharl Müstair",
    "original_id": null,
    "timetype": "no",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1773,
    "id": 7146529,
    "meta.biking": 102.7,
    "meta.snowshoe_trekking": 464.2,
    "meta.walking": 309.5,
    "meta.winter_hiking": 386.9,
    "modified_at": "2021-07-22T16:02:44Z",
    "name": "210717 Postautotour Bernina St Moritz",
    "original_id": null,
    "timetype": "no",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1773,
    "id": 7146530,
    "meta.biking": 267.5,
    "meta.snowshoe_trekking": 972.2,
    "meta.walking": 648.1,
    "meta.winter_hiking": 810.1,
    "modified_at": "2021-07-22T16:03:19Z",
    "name": "210717 Postautotour Julier Tiefencastel",
    "original_id": null,
    "timetype": "no",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1773,
    "id": 7146534,
    "meta.biking": 120.4,
    "meta.snowshoe_trekking": 509.0,
    "meta.walking": 339.3,
    "meta.winter_hiking": 424.1,
    "modified_at": "2021-07-22T16:03:55Z",
    "name": "210718 Postautotour Lenzerheide Thusis",
    "original_id": null,
    "timetype": "no",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1773,
    "id": 7146536,
    "meta.biking": 228.2,
    "meta.snowshoe_trekking": 1011.2,
    "meta.walking": 674.1,
    "meta.winter_hiking": 842.6,
    "modified_at": "2021-07-22T16:04:40Z",
    "name": "210718 Postautotour Glaspass Chur",
    "original_id": null,
    "timetype": "wander",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1774,
    "id": 1666239786,
    "meta.biking": 52.1,
    "meta.snowshoe_trekking": 265.4,
    "meta.walking": 176.9,
    "meta.winter_hiking": 221.1,
    "modified_at": "2021-07-25T17:15:03Z",
    "name": "210723 Rigi Hohflue",
    "original_id": null,
    "timetype": "wander",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1774,
    "id": 1521347853,
    "meta.biking": 86.4,
    "meta.snowshoe_trekking": 256.4,
    "meta.walking": 170.9,
    "meta.winter_hiking": 213.6,
    "modified_at": "2021-10-12T12:40:26Z",
    "name": "201226 Balmberg Weissenstein",
    "original_id": null,
    "timetype": "wander",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1774,
    "id": 2119274758,
    "meta.biking": 71.4,
    "meta.snowshoe_trekking": 289.8,
    "meta.walking": 193.2,
    "meta.winter_hiking": 241.5,
    "modified_at": "2021-10-12T12:40:26Z",
    "name": "201129 Beichle",
    "original_id": null,
    "timetype": "wander",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 79387,
    "id": 331336249,
    "meta.biking": 94.7,
    "meta.snowshoe_trekking": 306.3,
    "meta.walking": 204.2,
    "meta.winter_hiking": 255.2,
    "modified_at": "2021-08-14T20:39:51Z",
    "name": "201229 Brisenhuis",
    "original_id": null,
    "timetype": "wander",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1773,
    "id": 2001418283,
    "meta.biking": 78.7,
    "meta.snowshoe_trekking": 552.0,
    "meta.walking": 368.0,
    "meta.winter_hiking": 460.0,
    "modified_at": "2021-09-07T18:23:37Z",
    "name": "210907 Arvigrat",
    "original_id": null,
    "timetype": "wander",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1774,
    "id": 26170594,
    "meta.biking": 164.3,
    "meta.snowshoe_trekking": 647.1,
    "meta.walking": 431.4,
    "meta.winter_hiking": 539.2,
    "modified_at": "2021-09-23T14:36:46Z",
    "name": "210923 Hohgant",
    "original_id": null,
    "timetype": "wander",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1774,
    "id": 7117155,
    "meta.biking": 116.3,
    "meta.snowshoe_trekking": 377.1,
    "meta.walking": 251.4,
    "meta.winter_hiking": 314.2,
    "modified_at": "2021-09-26T13:07:36Z",
    "name": "200612 Gastlosen",
    "original_id": null,
    "timetype": "wander",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 1774,
    "id": 1631355292,
    "meta.biking": 79.1,
    "meta.snowshoe_trekking": 300.0,
    "meta.walking": 200.0,
    "meta.winter_hiking": 250.0,
    "modified_at": "2021-10-01T17:48:51Z",
    "name": "211001 Sattelegg Chli Aubrig",
    "original_id": null,
    "timetype": "wander",
    "tracked_time": null,
    "type": "normal"
  },
  {
    "filter_id": 160146,
    "id": 670856161,
    "meta.biking": null,
    "meta.snowshoe_trekking": null,
    "meta.walking": null,
    "meta.winter_hiking": null,
    "modified_at": null,
    "name": "240725 Donnerstags Schwimmer",
    "original_id": null,
    "timetype": null,
    "tracked_time": 49.2,
    "type": "recordedtrack"
  },
  {
    "filter_id": 160146,
    "id": 845967708,
    "meta.biking": null,
    "meta.snowshoe_trekking": null,
    "meta.walking": null,
    "meta.winter_hiking": null,
    "modified_at": null,
    "name": "240815 Sure Buvette",
    "original_id": null,
    "timetype": null,
    "tracked_time": 65.5,
    "type": "recordedtrack"
  },
  {
    "filter_id": 160146,
    "id": 1026928570,
    "meta.biking": null,
    "meta.snowshoe_trekking": null,
    "meta.walking": null,
    "meta.winter_hiking": null,
    "modified_at": null,
    "name": "240830 Eich Nottwil",
    "original_id": null,
    "timetype": null,
    "tracked_time": 89.21666666666667,
    "type": "recordedtrack"
  },
  {
    "filter_id": 160146,
    "id": 1211483909,
    "meta.biking": null,
    "meta.snowshoe_trekking": null,
    "meta.walking": null,
    "meta.winter_hiking": null,
    "modified_at": null,
    "name": "230823 Gamma-Inseli",
    "original_id": null,
    "timetype": null,
    "tracked_time": 53.35,
    "type": "recordedtrack"
  },
  {
    "filter_id": 160146,
    "id": 874934713,
    "meta.biking": null,
    "meta.snowshoe_trekking": null,
    "meta.walking": null,
    "meta.winter_hiking": null,
    "modified_at": null,
    "name": "230822 See",
    "original_id": null,
    "timetype": null,
    "tracked_time": 42.03333333333333,
    "type": "recordedtrack"
  },
  {
    "filter_id": 160146,
    "id": 843048272,
    "meta.biking": null,
    "meta.snowshoe_trekking": null,
    "meta.walking": null,
    "meta.winter_hiking": null,
    "modified_at": null,
    "name": "230818 Boje",
    "original_id": null,
    "timetype": null,
    "tracked_time": 39.9,
    "type": "recordedtrack"
  },
  {
    "filter_id": 160146,
    "id": 2067653788,
    "meta.biking": null,
    "meta.snowshoe_trekking": null,
    "meta.walking": null,
    "meta.winter_hiking": null,
    "modified_at": null,
    "name": "230623 See",
    "original_id": null,
    "timetype": null,
    "tracked_time": 75.6,
    "type": "recordedtrack"
  },
  {
    "filter_id": 160146,
    "id": 833620079,
    "meta.biking": null,
    "meta.snowshoe_trekking": null,
    "meta.walking": null,
    "meta.winter_hiking": null,
    "modified_at": null,
    "name": "230812 Badi Schenkon",
    "original_id": null,
    "timetype": null,
    "tracked_time": 28.833333333333332,
    "type": "recordedtrack"
  }
]